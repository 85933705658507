import { render, staticRenderFns } from "./RadioGroup.vue?vue&type=template&id=83cadd68&scoped=true&"
import script from "./RadioGroup.vue?vue&type=script&lang=js&"
export * from "./RadioGroup.vue?vue&type=script&lang=js&"
import style0 from "./RadioGroup.vue?vue&type=style&index=0&id=83cadd68&prod&lang=scss&scoped=true&"
import style1 from "./RadioGroup.vue?vue&type=style&index=1&id=83cadd68&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83cadd68",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseInputField: require('/vercel/path0/components/ui/forms/BaseInputField.vue').default})
