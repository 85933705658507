
import { useIdMixin } from '@teamnovu/utils'
import snarkdown from 'snarkdown'

export default {
  mixins: [useIdMixin({ name: 'uniqueId' })],

  inheritAttrs: false,
  props: {
    value: {
      type: [Boolean, String, Number, Array],
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    instructions: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      internalValue: this.value,
      error: null,
    }
  },

  watch: {
    internalValue() {
      this.$emit('input', this.internalValue)
    },
    value() {
      this.internalValue = this.value
    },
  },

  mounted() {
    this.targetBlankAttribute()
  },

  methods: {
    snarkdown,

    targetBlankAttribute() {
      const links = this.$el.querySelectorAll('a')

      links.forEach((link) => {
        link.setAttribute('target', '_blank')
      })
    },
  },
}
