
import { useIdMixin } from '@teamnovu/utils'
import snarkdown from 'snarkdown'

export default {
  mixins: [useIdMixin({ name: 'uniqueId' })],

  inheritAttrs: false,
  props: {
    value: {
      type: [Boolean, String, Number, Array],
      default: () => [],
    },
    rules: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    instructions: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      internalValue: this.value,
      error: null,
    }
  },

  watch: {
    internalValue() {
      this.$emit('input', this.internalValue)
    },
    value() {
      this.internalValue = this.value
    },
  },

  methods: {
    snarkdown,
  },
}
