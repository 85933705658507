
export default {
  inheritAttrs: false,
  props: {
    value: {
      validator: (v) => true,
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      image: this.value,
      error: null,
    }
  },

  computed: {
    computedFileName() {
      if (!this.image) return 'Bild hierhin ziehen'

      if (this.multiple) {
        return this.image[0].name
      }

      return this.image.name
    },
  },
  watch: {
    image() {
      this.$emit('input', this.image)
    },
    value() {
      this.image = this.value
    },
  },
  methods: {
    onDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      this.createFile(files[0])
    },

    onChange(e) {
      const files = e.target.files
      this.createFile(files[0])
    },

    createFile(file) {
      if (!file) return
      if (!file.type.match('image.*')) {
        this.error = 'Datei muss ein Bild sein'
        return
      }
      this.error = null

      if (this.multiple) {
        return (this.image = [file])
      }

      this.image = file
    },

    removeFile() {
      this.image = null
    },
  },
}
