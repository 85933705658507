import { render, staticRenderFns } from "./ImageModal.vue?vue&type=template&id=5de82150&"
import script from "./ImageModal.vue?vue&type=script&lang=js&"
export * from "./ImageModal.vue?vue&type=script&lang=js&"
import style0 from "./ImageModal.vue?vue&type=style&index=0&id=5de82150&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Close: require('/vercel/path0/components/icons/Close.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default})
