
import Vue100vh from 'vue-100vh'

export default {
  components: { Vue100vh },

  props: {
    url: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      scrollY: null,
    }
  },

  beforeMount() {
    this.preventScrolling(true)
  },

  beforeDestroy() {
    this.preventScrolling(false)
  },

  methods: {
    preventScrolling(yes) {
      const body = document.body

      if (yes) {
        this.scrollY = window.scrollY
        document.body.style.overflowY = 'hidden'
        document.body.style.left = 0
        document.body.style.right = 0
      } else {
        body.style.overflowY = 'auto'
        body.style.position = ''
        body.style.top = ''
        body.style.left = ''
        body.style.right = ''
        window.scrollTo(0, this.scrollY)
      }
    },
  },
}
