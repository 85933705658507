
export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    whiteBackground: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      formFields: null,
      form: {},
      cashBoxes: [],
      boxImageModalIsOpen: false,
      state: 'idle',
    }
  },

  computed: {
    submitionForm() {
      return this.content.submition_form[0]
    },
    showEventField() {
      return (field) => {
        if (
          field.handle === 'vorname_begleitung' ||
          field.handle === 'nachname_begleitung'
        ) {
          return +this.form.anzahl_gaeste === 2
        }
        return true
      }
    },
  },

  mounted() {
    this.loadForm()
  },

  methods: {
    async loadForm() {
      if (this.submitionForm) {
        const { data } = await this.$axios.$get(
          `forms/${this.submitionForm.handle}`
        )
        const formFields = await Promise.all(
          data.map(async (field) => {
            if (field.import === 'auswahl_sammelkaesseli') {
              const cashBoxes = await this.$axios.$get('globals/cash_box')

              const options = cashBoxes.data.cash_boxes.reduce((prev, curr) => {
                prev[curr.name] = null
                return prev
              }, {})

              this.cashBoxes = cashBoxes.data.cash_boxes.reduce(
                (prev, curr) => {
                  prev[curr.name] = this.imageUrl(curr.image)
                  return prev
                },
                {}
              )

              return [
                {
                  field: {
                    width: 50,
                    display: 'Auswahl Sammelkässeli',
                    validate: ['required'],
                    type: 'radio',
                    options,
                  },
                  handle: 'auswahl_sammelkaesseli',
                },
                {
                  field: {
                    width: 30,
                    display: 'Stückzahl',
                    validate: ['required', 'max_value:3'],
                    type: 'integer',
                  },
                  handle: 'stueckzahl',
                },
                // uncomment when box_image is needed
                // {
                //   field: {
                //     display: 'Sammelkässeli anzeigen',
                //     type: 'box_image_modal',
                //   },
                //   handle: 'box_image',
                // },
              ]
            }
            return field
          })
        )
        this.formFields = formFields.flatMap((b) => b)
      }
    },
    selectFieldOptions(options) {
      if (!options) return []
      return Object.keys(options).map((key) => ({
        value: key,
        label: options[key] || key,
      }))
    },
    getLabel(field) {
      if (field.validate && field.validate.includes('required')) {
        return `${field.display}*`
      }
      return field.display
    },
    getRules(field, handle) {
      let rules = ''

      if (field.validate && field.validate.includes('required')) {
        if (field.type === 'checkboxes') rules += 'required|min:1'
        else rules += 'required'
      }
      if (
        (handle === 'vorname_begleitung' || handle === 'nachname_begleitung') &&
        +this.form.anzahl_gaeste === 2
      ) {
        rules = this.addRule(rules, 'required')
      }

      if (field.type === 'integer') {
        rules = this.addRule(rules, 'numeric')
      }
      if (field.validate && field.validate.includes('email')) {
        rules = this.addRule(rules, 'email')
      }
      if (field.validate && field.validate.some((rule) => rule.match(/max/))) {
        const rule = field.validate.find((rule) => rule.match(/max/))
        rules = this.addRule(rules, rule)
      }

      return rules
    },
    addRule(rules, rule) {
      if (rules) {
        rules += '|'
      }
      rules += rule
      return rules
    },
    submitForm() {
      this.state = 'pending'

      const formData = new FormData()

      Object.keys(this.form).forEach((key) => {
        if (this.form[key] && this.form[key].value) {
          formData.append(key, this.form[key].value)
        } else {
          formData.append(key, this.form[key])
        }
      })

      this.$axios
        .post(`forms/${this.submitionForm.handle}`, formData, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        })
        .then((response) => {
          this.state = 'success'
          this.$emit('submitted')

          setTimeout(() => {
            this.$refs.title?.scrollIntoView({ behavior: 'smooth' })
          }, 50)
        })
        .catch(({ response }) => {
          if (response.status === 422 && response.data.errors) {
            this.$refs.form.setErrors(response.data.errors)
          }
          this.state = 'error'
        })
    },
  },
}
